/**
 * Determines and retrieves the name of the browser being used by the user.
 *
 * @memberof module:Global/Functions
 * @function getBrowser
 * @returns {"Chrome" |
 *   "Safari" |
 *   "Firefox" |
 *   "Opera" |
 *   "Edge" |
 *   "Explorer" |
 *   "Unknown"} The name of the browser.
 */
export default () => {
  const { userAgent } = navigator;

  if (userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  }

  if (userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  }

  if (userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  }

  if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
    return "Opera";
  }

  if (userAgent.indexOf("Edge") !== -1) {
    return "Edge";
  }

  if (
    userAgent.indexOf("MSIE") !== -1 ||
    userAgent.indexOf("Trident/") !== -1
  ) {
    return "Explorer";
  }

  return "Unknown";
};
