/**
 * @file Global utility functions.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module Global/Functions
 */

export { default as getBrowser } from "./getBrowser";
export { default as getCurrentYear } from "./getCurrentYear";
export { default as getMobileOS } from "./getMobileOS";
export { default as isMobile } from "./isMobile";
export { default as redirectToAppStore } from "./redirectToAppStore";
