/**
 * Determines whether the user is in a mobile device or not.
 *
 * @memberof module:Global/Functions
 * @function isMobile
 * @returns {boolean} Returns `true` if the user is using a mobile device.
 *   Otherwise, returns `false`.
 */
export default () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return userAgent.match(/android/i) ||
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPod/i) ||
    userAgent.match(/windows phone/i);
};
