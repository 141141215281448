/* eslint-disable max-len */
import getMobileOS from "./getMobileOS";

/**
 * Redirects the user to the appropriate app store based on the OS they are
 * using on their mobile device.
 *
 * @memberof module:Global/Functions
 * @function redirectToAppStore
 * @returns {void} Nothing.
 */
export default () => {
  const OS = getMobileOS();

  if (OS === "iOS") {
    window.location.href = "https://apps.apple.com/us/app/crush-the-app/id1666352323";
  } else {
    window.location.href = "https://play.google.com/store/apps/details?id=com.crushsocialnetwork.crush";
  }
};
