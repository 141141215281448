/* eslint-disable radix */

/**
 * @file Mouse cursor script.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module Scripts/Cursor
 */

import { isMobile } from "../utils/global";

const mouseCursor = document.querySelector(".cursor");
const links = document.querySelectorAll("a");
let previousScroll = { x: window.scrollX, y: window.scrollY };

window.addEventListener("mousemove", handleOnMouseMove);
window.addEventListener("scroll", handleOnScroll);
["load", "resize", "orientationchange"].map(
  (type) => window.addEventListener(
    type,
    handleCursorVisibility
  )
);

links.forEach((link) => {
  link.addEventListener("mouseover", handleOnLinkMouseOver);
  link.addEventListener("mouseleave", handleOnLinkMouseLeave);
});

/**
 * Handles the cursor visibility, making sure it is not visible on
 * mobile devices.
 *
 * @private
 * @function handleCursorVisibility
 * @returns {void} Nothing.
 * @requires module:Global/Functions.isMobile
 */
function handleCursorVisibility() {
  mouseCursor.style.display = isMobile() ? "none" : "block";
}

/**
 * Handles mouse movement, updating the cursor position.
 *
 * @private
 * @function handleOnMouseMove
 * @param {MouseEvent} e - The mouse event.
 * @returns {void} Nothing.
 * @requires module:Global/Functions.isMobile
 */
function handleOnMouseMove({ pageX, pageY }) {
  if (isMobile()) return;

  mouseCursor.style.left = `${pageX}px`;
  mouseCursor.style.top = `${pageY}px`;
}

/**
 * Handles page scroll events, updating the cursor position.
 *
 * @private
 * @function handleOnScroll
 * @returns {void} Nothing.
 * @requires module:Global/Functions.isMobile
 */
function handleOnScroll() {
  if (isMobile()) return;

  const currentScroll = { x: window.scrollX, y: window.scrollY };
  const xDiff = currentScroll.x - previousScroll.x;
  const yDiff = currentScroll.y - previousScroll.y;
  const currentLeft = Math.max(parseInt(mouseCursor.style.left), 0);
  const currentTop = Math.max(parseInt(mouseCursor.style.top), 0);
  const newLeft = currentLeft + xDiff;
  const newTop = currentTop + yDiff;

  mouseCursor.style.left = `${newLeft}px`;
  mouseCursor.style.top = `${newTop}px`;
  previousScroll = currentScroll;
}

/**
 * Handles mouseover events on the page links and adds a CSS class to
 * the cursor.
 *
 * @private
 * @function handleOnLinkMouseOver
 * @returns {void} Nothing.
 * @requires module:Global/Functions.isMobile
 */
function handleOnLinkMouseOver() {
  if (isMobile()) return;

  mouseCursor.classList.add("link-grow");
}

/**
 * Handles mouseleave events on the page links and removes a CSS class from
 * the cursor.
 *
 * @private
 * @function handleOnLinkMouseLeave
 * @returns {void} Nothing.
 * @requires module:Global/Functions.isMobile
 */
function handleOnLinkMouseLeave() {
  if (isMobile()) return;

  mouseCursor.classList.remove("link-grow");
}
